import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import scrollTo from 'gatsby-plugin-smoothscroll'

class Header extends React.Component {
  state = { isActive: false }

  handleClick = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }))
  }

  handleLinkClick = selector => {
    this.setState({ isActive: false })
    scrollTo(selector)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            file(relativePath: { regex: "/nessia-logo/" }) {
              childImageSharp {
                fixed(width: 125) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        `}
        render={({ file }) => (
          <header>
            <nav
              className="navbar"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="container">
                <div className="navbar-brand">
                  <Link
                    to={this.props.locale === 'fr' ? '/' : '/en/'}
                    className="navbar-item"
                    id="navbar_logo"
                  >
                    <Img fixed={file.childImageSharp.fixed} alt="NessIA Logo" />
                  </Link>

                  <BurgerMenu
                    isActive={this.state.isActive}
                    onClick={this.handleClick}
                  />
                </div>
                <div
                  className={`navbar-menu ${this.state.isActive &&
                    'is-active is-active-menu'}`}
                >
                  <div className="navbar-end">
                    <div
                      className="navbar-item"
                      onClick={() => this.handleLinkClick('#services')}
                    >
                      {this.props.data[0]}
                    </div>
                    <div
                      className="navbar-item"
                      onClick={() => this.handleLinkClick('#about')}
                    >
                      {this.props.data[1]}
                    </div>
                    <div
                      className="navbar-item"
                      onClick={() => this.handleLinkClick('#testimonials')}
                    >
                      {this.props.data[2]}
                    </div>
                    <div id="button" className="navbar-item">
                      <button
                        className="button is-primary is-outlined"
                        onClick={() => this.handleLinkClick('#contact')}
                      >
                        <strong>{this.props.data[3]}</strong>
                      </button>
                    </div>
                    <Link
                      className="navbar-item"
                      to={this.props.locale === 'fr' ? '/en/' : '/'}
                    >
                      {this.props.data[4]}
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        )}
      />
    )
  }
}

const BurgerMenu = ({ isActive, onClick }) => (
  <div
    role="button"
    className={`navbar-burger ${isActive && 'is-active'}`}
    aria-label="menu"
    aria-expanded="false"
    onClick={onClick}
  >
    <span aria-hidden="true" />
    <span aria-hidden="true" />
    <span aria-hidden="true" />
  </div>
)

export default Header
