import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ title, subtitle, __className, white, children }) => (
  <div className={`columns ${__className}`}>
    <div className="column is-full has-text-centered">
      <h1
        className={`title is-size-2-fullhd is-size-3 is-spaced ${white &&
          'has-text-white'}`}
      >
        {title}
      </h1>
      {subtitle && (
        <h2
          className={`subtitle is-size-4-fullhd is-size-5 ${white &&
            'has-text-white'}`}
        >
          {subtitle}
        </h2>
      )}
      {children}
    </div>
  </div>
)

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  __className: PropTypes.string,
  white: PropTypes.bool,
}

export default Title
