import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import socialLogo from '../images/nessia_logo_social.png'
import '../styles/index.scss'

const Layout = ({ children, locale }) => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        site {
          siteMetadata {
            title_fr
            description_fr
            nav_fr
            title_en
            description_en
            nav_en
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <>
        <Helmet
          title={siteMetadata[`title_${locale}`]}
          meta={[
            {
              name: 'description',
              content: siteMetadata[`description_${locale}`],
            },
            { property: 'og:title', content: siteMetadata[`title_${locale}`] },
            {
              property: 'og:description',
              content: siteMetadata[`description_${locale}`],
            },
            { property: 'og:image', content: socialLogo },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: 'https://nessia.ca' },
            { name: 'twitter:card', value: 'summary' },
          ]}
        >
          <html lang={locale} />
        </Helmet>
        <Header data={siteMetadata[`nav_${locale}`]} locale={locale} />
        {children}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
